import React from "react";
import PropTypes from "prop-types";
import BackgroundImage from "gatsby-background-image";

const HeaderGlobal = ({ hTitle, hSubtitle, hTextOne, hTextTwo, image }) => {
  return (
    <header className="m-headerGlobal">
      <div className="_wr m-headerGlobal__wrapper" style={{ backgroundImage: `url("${image}")` }}>
        <div className="_w m-headerGlobal__content">
          <div className="_xs10 _s8 _l5 ">
            <h1 className="m-headerGlobal__content--title">{hTitle}</h1>
            {hSubtitle ? <p className="a-text -red">{hSubtitle}</p> : ""}
            <p className="m-headerGlobal__content--text -first">{hTextOne}</p>
            {hTextTwo ? (
              <p className="m-headerGlobal__content--text -second">
                {hTextTwo}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

HeaderGlobal.defaultProps = {
  hTitle: "Lorem ipsum",
  hTextOne:
    "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, tempore?",
};

HeaderGlobal.propTypes = {
  hTitle: PropTypes.string,
  hSubtitle: PropTypes.string,
  hTextOne: PropTypes.string,
  hTextTwo: PropTypes.string,
};

export default HeaderGlobal;
