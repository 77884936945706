import React from "react";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { Swiper, SwiperSlide } from 'swiper/react';
import LogoEaro from '../../assets/images/global/earo.png'
import LogoQcc from '../../assets/images/global/grundfos.png'
import 'swiper/css';



const Projects = () => {
  const { t } = useTranslation()

  const projects = [
    {
      image: LogoQcc,
      title: `${t('projects.list.qcc.headline')}`,
      text: `${t('projects.list.qcc.text')}`,
      linkTo: "/quality-control",
      modifier: '-qcc'
    },
    {
      image: LogoEaro,
      title: `${t('projects.list.earo.headline')}`,
      text: `${t('projects.list.earo.text')}`,
      linkTo: "/e-aro",
      modifier: '-earo'
    }
  ];


  const breakpointsObj = {
    576: {
      slidesPerView: 1.6,
      spaceBetween: 30
    },
    992: {
      slidesPerView: 2,
    },
  }

  return (
    <section className="m-projects section-padding">
      <div className="_wr">
        <h2 className="-thin">{t('projects.headline')}</h2>
        <p className="a-text -red">{t('projects.text')}</p>
        <div className="m-projects__cards">
          <Swiper
            spaceBetween={20}
            slidesPerView={1.4}
            breakpoints={breakpointsObj}
          >
            {projects.map(({ image, title, text, linkTo, modifier }, key) => (
              <SwiperSlide key={key}>
                <div className={`m-projects__card ${modifier}`}>
                  <div className="m-projects__card--imageContainer">
                    <div className="m-projects__card--image">
                      <img src={image} />
                    </div>
                  </div>
                  <div className="m-projects__card--content">
                    <h3 className="m-projects__card--title">{title}</h3>
                    <p className="m-projects__card--text">{text}</p>
                    <Link to={linkTo} className="a-btn -redBorder a-hover -effectThree">
                      {t('buttons.discoverCaseStudy')}
                      <span className="a-arrow -long"></span>
                    </Link></div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Projects;
